// Customized classes
// Split in another file so that it would compute after
// Foundation's own SCSS files.

$menu-radius: 20px;
.menu.horizontal * {
    // display: inline-block;
    border-radius: $menu-radius;
}

@media only screen and (min-width: 40.063em) {
  .menu.horizontal * {
    display: inline-block;
    border-radius: $menu-radius;
  }
  .menu.medium-horizontal > li{
    display: inline-block !important;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    display: inline-block;
  }
}

.menu.vertical * {
    // display: table-cell;
    border-radius: $menu-radius;
}

.menu {
  width: 100%;
  border-radius: $menu-radius;
  li {
    background: $black;

    &:hover {
      background: lighten($red, 5%);
    }
  }
  &.dark {
    background: $black;
  }
}

.logo {
  width: 200px;
}

.top-bar .menu a {
    color: $white;
}
.title-bar .menu a {
    color: $white;
}
.title-bar {
  &.light {
    // background: transparent;
    background: $white;
  }
  &.transp {
    background: transparent;
    // background: $white;
  }
  &.sticky {

  border-style: solid;
  border-bottom-color: $primary-color;
  }
  width: 100%;
  // &.logo {
  //   overflow: auto;
  //   img {
  //     float: left;
  //     max-height: 100px;
  //   }
  //   h1 {
  //     position: relative;
  //     vertical-align: middle;
  //   }
  // }
}

.title-bar-right {
  position: relative;
  right: 0;
  bottom: 0;

  @media only screen and (min-width: 40.063em) {
    min-height: 75px;
    .title-bar {
      position: absolute;
      bottom: 0;
    }
  }

  #small-menu {
    text-align: left;
  }
}

@media screen and (max-width: 39.9375em) {
  .title-bar {
    margin: 0;
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .title-bar-right {
    padding-left: 0;
    padding-right: 0;
  }
}

// Fix hairline width bug with flexbox
hr {
  width: 100%;
}

#contatos-page p {
  margin-bottom: 0.5em;
}

#contatos-page ul {
  padding-left: 2em;
}

.fixed-cards {
  .columns {
    display: inline-block;
  }
  .card {
    width: 200px;
    height: 270px;
    display: inline-block;

    img {
      max-height: 200px;
    }
    div {
      display: table-cell;
      vertical-align: middle;
      div.card-img {
        height: 200px;
      }
      .card-section {
        display: inline-block;
      }
    }
  }
}

// Fix anchor position bug due to sticky topbar
$anchor-offset: 130px;
h1, h2 {
  padding-top: $anchor-offset;
  margin-top: -$anchor-offset;
}

// Limit map size
.map {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

}

.praga {
  object {
    width: 200px;
  }
}

object.svgcard {
    max-width: 170px;
    max-height: 170px;
    pointer-events: none;
}
